.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

#cb-atlas-header-1 {
  border-top: 0;
  border-bottom: 1px;
}
#cb-atlas-identity-1 > div:first-child{
  background-color: #0077c8;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#root .cb-accordion-trigger {
  float: right;
}
#root .cb-accordion-trigger-panel.cb-in {
  border-top:0;
  border-bottom:0;
}
#root .cb-card {
  border-radius: 0 !important;
}
#root .cb-widget .cb-k-12-program .cb-login-img-container, .cb-widget .cb-k-12-color .cb-login-img-container {
  background-color: #0077c8;
}
#root .cb-accordion-trigger > span {
  font-size: 12px;
}
#accept-tc-1 > div > label {
  text-transform: none;
}
.yesButton > button, .noButton > button {
  width: 120px;
}
.yesButton > button, .cb-confirm-cancel {
  background-color: #0077c8 !important;
  border: 1px solid  #0077c8 !important;
}
.footerButton {
  text-align: center;
}
.noButton > button, .footerButton > button, .cb-stay-registered  {
  border: 1px solid  #0077c8 !important;
  color:  #0077c8 !important;
}
.yesButton > button:disabled, .footerButton > button:disabled, .noButton > button:disabled {
  border: 0 !important;
  color: #aaa !important;
  background-color:  #f0f0f0 !important;
}
.download-card:hover {
  cursor: pointer;
}
